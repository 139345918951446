<template>
    <el-row class="order-detail">
        <div class="detail-header">订单详情</div>
        <div class="order-info">
            <!-- 商品信息-->
            <div>
                <div class="content-header">商品信息</div>
                <div class="content-items">
                    <div class="content-item">
                        <div class="item-left">商品类型：</div>
                        <div>{{ order.className | ifEmpty }}</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">规格名称：</div>
                        <div>{{ order.specsName | ifEmpty }}</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">商品名称：</div>
                        <div>{{ order.name | ifEmpty }}</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">版本：</div>
                        <div>{{order.version | ifEmpty }}</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">商品状态：</div>
                        <div v-if="order.isSale === '0'">未上架</div>
                        <div v-else-if="order.isSale === '1'">已经上架</div>
                        <div v-else>--</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">接入类型：</div>
                        <div v-if="order.accessType === 1">镜像</div>
                        <div v-if="order.accessType === 2">人工服务</div>
                        <div v-if="order.accessType === 3">Saas</div>
                        <div v-if="order.accessType === 4">解决方案</div>
                        <div v-if="order.accessType === 5">API</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">发布时间：</div>
                        <div>{{ order.releaseTime | ifEmpty }}</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">上架时间：</div>
                        <div>{{ order.releaseTime | ifEmpty }}</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">商品子类：</div>
                        <div>{{ order.childType | ifEmpty }}</div>
                    </div>
                </div>
            </div>


            <!-- 订单信息 -->
            <div>
                <div class="content-header">订单信息</div>
                <div class="content-items">
                    <div class="content-item">
                        <div class="item-left">订单号：</div>
                        <div>{{ order.orderNo }}</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">订单状态：</div>
                        <div v-if="order.status === 0">待付款</div>
                        <div v-if="order.status === 1">待发货</div>
                        <div v-if="order.status === 2">已发货</div>
                        <div v-if="order.status === 3">已完成</div>
                        <div v-if="order.status === 5">取消交易</div>
                        <div v-if="order.status === 6">退货申请</div>
                        <div v-if="order.status === 7">退货审核</div>
                        <div v-if="order.status === 8">退货中</div>
                        <div v-if="order.status === -5">已退货</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">创建时间：</div>
                        <div>{{ order.createTime | ifEmpty }}</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">支付时间：</div>
                        <div>{{ order.paymentTime | ifEmpty }}</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">订单类型：</div>
                        <div v-if="order.orderType === 1">新购</div>
                        <div v-if="order.orderType === 2">续费</div>
                        <div v-if="order.orderType === 3">升级</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">计费模式：</div>
                        <div v-if="order.specsType === 'month_price'">月</div>
                        <div v-else-if="order.specsType === 'year_price'">年</div>
                        <div v-else>--</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">购买时长：</div>
                        <div>{{ order.num }}</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">实付金额：</div>
                        <div>￥{{ order.price }}</div>
                    </div>
                </div>
            </div>


            <!-- 其他信息-->
            <div>
                <div class="content-header">其他信息</div>
                <div class="content-column-items">
                    <div class="content-item">
                        <div class="item-left">评论：</div>
                        <div>{{ order.isComment === 1 ? '已评论' : '未评论' }}</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">开票：</div>
                        <div v-if="order.isInvoice === 0">未开票</div>
                        <div v-if="order.isInvoice === 1">已开票</div>
                        <div v-if="order.isInvoice === 2">已取消</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">合同：</div>
                        <div v-if="order.isContract === 0">未申请</div>
                        <div v-if="order.isContract === 1">已申请</div>
                        <div v-if="order.isContract === 2">已取消</div>
                    </div>
                </div>
            </div>

            <!-- 商品账户信息-->
            <div>
                <div class="content-header">商品账户信息</div>
                <div class="content-column-items">
                    <div class="content-item">
                        <div class="item-left">商品链接：</div>
                        <div>{{ account.frontEndUrl | ifEmpty('暂无') }}</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">登录邮箱：</div>
                        <div>{{ account.userName | ifEmpty('暂无') }}</div>
                    </div>
                    <div class="content-item">
                        <div class="item-left">登录密码：</div>
                        <div style="width: 25%">{{ password | ifEmpty('暂无') }}</div>
                        <div>
                            <i :class="{'el-icon-view': true, 'ifView': true, 'icon-view': !ifHide}" @click="showPassword"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="back-list">
            <el-button type="danger" @click="backOrderList">返回</el-button>
        </div>
    </el-row>
</template>

<script>
import api from '@/api/buyer/orderInfo'
export default {
    name: "orderInfo",
    filters: {
        ifEmpty(val, emptyText) {
            if(!val) {
                return emptyText ? emptyText : '--';
            }
            return val;
        }
    },
    data() {
        return {
            //商品信息
            productInfo: {
                type: '操作系统',
                spaces: '规格名称',
                name: '云文档管理系统',
                version: 'V1.0',
                status: 1,
                accessType: 'Saas',
                finishTime: '2022-08-03 11:10:10',
                onShelfTime: '2022-08-03 11:10:10',
                childType: ''
            },
            //订单信息
            orderInfo: {
                orderNo: 'O202202281541064977',
                orderStatus: 1,
                createTime: '2022-08-03 11:10:10',
                payTime: '',
                orderType: 1,
                billingMode: 1,
                buyTime: 1,
                price: 0.00,
            },
            //其他相关信息
            other: {

            },
            //商品账户信息
            accountInfo: {
                url: 'http://www.baidu.com',
                email: 'test@edensoft.com.cn',
                pwd: '123456',
                encryptPwd: '******'
            },
            ifHide: true,
            orderId: '',
            shopId: '',
            order: {},
            user: {},
            account: {},
            password: '********',
        }
    },
    mounted() {
        this.orderId = sessionStorage.getItem('orderId');
        this.shopId = sessionStorage.getItem('shopId');
        this.getOrderInfo();
    },
    methods: {
        backOrderList() {
            this.$router.push('/buyer/cloudOrders')
        },
        getOrderInfo() {
            api.getOrderInfo({
                orderId: this.orderId,
                shopId: this.shopId
            }).then(res => {
                let data = res.data;
                if(data) {
                    this.order = data.ecOrder;
                    if(this.order && this.order.dataInfo) {
                        this.account = JSON.parse(this.order.dataInfo);
                    }
                    this.user = data.ecUser;
                }
            })
        },
        showPassword() {
            this.ifHide = !this.ifHide;
            if(this.ifHide) {
                this.password = '********';
                return;
            }
            api.getOrderPassword({
                orderId: this.orderId
            }).then(res => {
                if(res.code != 200) {
                    this.password = '';
                    return this.buyerMsg('当前购买的商品没有密码，请联系管理员', 'warning');
                }
                this.password = res.textValue;
            })
        }
    }
}
</script>

<style scoped>
.order-detail {
    width: 100%;
    font-size: 14px;
    padding: 1% 0% 0% 3%;
}
.order-info {
    margin-bottom: 2%;
}
.detail-header {
    color: #f6837a;
    font-size: 24px;
}
.content-header {
    font-size: 18px;
    color: #F56E65;
    margin-top: 2%;
    margin-bottom: 0.5%;
}
.content-items {
    display: flex;
    width: 95%;
    flex-wrap: wrap;
    margin-left: 2%;
}
.content-column-items {
    display: flex;
    width: 95%;
    flex-direction: column;
    margin-left: 2%;
}
.content-item {
    display: flex;
    width: 50%;
    margin-top: 1.5%;
}

.item-left {
    width: 13%;
}
.ifView {
    font-size: 18px;
}
.ifView:hover, .icon-view {
    color: #F56E65;
}
.back-list {
    margin-bottom: 2%;
}
</style>